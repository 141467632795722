import React from 'react'
import { Button, Header, Label, useAuth } from '@nextelco/common-ui'

import HomeImg from '../assets/svgs/Home_NEXTelco.svg'
import ProefImg from '../assets/svgs/proef_1.svg'
import HomeTitleImg from '../assets/svgs/NEXTelco_logo.svg'

import styles from './NotFoundPage.module.scss'
import { useNavigate } from 'react-router-dom'

type Props = {}

const NotFoundPage = (props: Props) => {
  const { logout, user } = useAuth()
  const navigate = useNavigate()

  return (
    <div className={styles['landing-page']}>
      <Header
        returnToHome={() => {}}
        returnLogout={logout}
        user={user!}
        homeImg={HomeImg}
        homeTextImg={HomeTitleImg}
        logoImg={ProefImg}
      />
      <div>
        <p>O que procuras não existe.</p>
        <button onClick={() => navigate('/')}>Voltar para o início</button>
      </div>
    </div>
  )
}

export default NotFoundPage
