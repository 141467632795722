import React from 'react'
import App from './App'
import ReactDOM from 'react-dom/client'
import { AuthProvider, Loading, useAuth } from '@nextelco/common-ui'
import './styles/index.module.scss'

const requiredEnvVars = [
  'REACT_APP_API_URL',
  'REACT_APP_KEYCLOAK_URL',
  'REACT_APP_KEYCLOAK_REALM',
  'REACT_APP_KEYCLOAK_CLIENT',
]

const Root: React.FC = () => {
  const authConfig = {
    keycloakConfig: {
      url: process.env.REACT_APP_KEYCLOAK_URL!,
      realm: process.env.REACT_APP_KEYCLOAK_REALM!,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT!,
    },
  }

  return (
    <AuthProvider config={authConfig}>
      <AuthenticatedApp />
    </AuthProvider>
  )
}

const AuthenticatedApp: React.FC = () => {
  const { authenticated, loading, login } = useAuth()

  if (loading) {
    return <Loading />
  }

  if (authenticated) {
    return <App />
  }

  return (
    <div>
      <p>You are not authenticated.</p>
      <button onClick={login}>Login</button>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<Root />)
