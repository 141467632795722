// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role=list],
ol[role=list] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg,
video,
canvas {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}
body,
html {
  height: 100%;
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/reset.scss"],"names":[],"mappings":"AAAA;;;EAGE,sBAAA;AACF;;AAEA;EACE,SAAA;EACA,UAAA;AACF;;AAEA;;EAEE,gBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;;;;;EAKE,eAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,sBAAA;AACF;;AAEA;;;;EAIE,aAAA;AACF;;AAEA;EACE;IACE,qBAAA;EACF;EACA;;;IAGE,qCAAA;IACA,uCAAA;IACA,sCAAA;IACA,gCAAA;IACA,gBAAA;EACF;AACF;AAEA;;EAEE,YAAA;EACA,uBAAA;AAAF","sourcesContent":["*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n\nul[role='list'],\nol[role='list'] {\n  list-style: none;\n}\n\nhtml:focus-within {\n  scroll-behavior: smooth;\n}\n\na:not([class]) {\n  text-decoration-skip-ink: auto;\n}\n\nimg,\npicture,\nsvg,\nvideo,\ncanvas {\n  max-width: 100%;\n  height: auto;\n  vertical-align: middle;\n  font-style: italic;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n}\n\n@media (prefers-reduced-motion: reduce) {\n  html:focus-within {\n    scroll-behavior: auto;\n  }\n  *,\n  *::before,\n  *::after {\n    animation-duration: 0.01ms !important;\n    animation-iteration-count: 1 !important;\n    transition-duration: 0.01ms !important;\n    scroll-behavior: auto !important;\n    transition: none;\n  }\n}\n\nbody,\nhtml {\n  height: 100%;\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
