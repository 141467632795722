import React, {useContext, useEffect} from "react"
import {HashRouter as Router, Route, Routes} from 'react-router-dom'
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

import Header from "./components/components/layout/Header"
import Footer from './components/components/layout/Footer.js'
import NotFound from "./components/pages/NotFound.js"
import Main from "./components/pages/Main.js"

//Operators
import GestOperators from "./components/pages/Operator/GestOperators.js"
import Operator from "./components/pages/Operator/Operator.js"

//templates
import Template from "./components/pages/Templates/Template.js"
import Material from "./components/pages/Templates/Material.js"
import AddTemplate from "./components/pages/Templates/AddTemplate.js"

//Regras
import AddRule from "./components/pages/Rules/AddRule.js"
import Rule from "./components/pages/Rules/Rule.js"
import SubRule from "./components/pages/Rules/SubRule.js"

//projects
import GestProjects from "./components/pages/Projects/GestProjects.js"
import Project from "./components/pages/Projects/Project.js"

//Users
import Users from "./components/pages/Users/GestUsers.js"

//logs
import Logs from "./components/pages/Logs/GestLogs.js"

//Locker
import LockingContext from './components/context/LockingContext'

//Auth
import useAuth from "./hooks/useAuth.js"
import { AuthProvider } from './components/context/AuthContext';
import Loading from "./components/components/layout/Loading.js"

export default function App() {
   const {isLoggedIn,user,privileges,models,admins,logout} = useAuth()
   const {operatorLocked, unlockOperator} = useContext(LockingContext)

   const ProtectedRoute = ({locking, element}) => {
      if (isLoggedIn) {
         if (!locking && operatorLocked) {
            console.log("UNLOCK!")
            unlockOperator()
         }
         return element
      }
   }

   return (
      <>
      {
         isLoggedIn ? (
            <AuthProvider value={{isLoggedIn,user,privileges,models,admins,logout}}>
            <Router>
               <Header />
               <div className="mainContent">
                  <Routes>
                     <Route exact path="/" element={<ProtectedRoute locking={false} element={<Main />} />} />

                     {/* Operators */}
                     <Route path="/gestOperators" element={<ProtectedRoute locking={false} element={<GestOperators />} />} />
                     <Route path="/operator/:idOperator" element={<ProtectedRoute locking={true} element={<Operator />} />} />
                  
                     {/* Templates */}
                     <Route path='/template/:idOperator/:templateDate' element={<ProtectedRoute locking={true} element={<Template />} />} />
                     <Route path="/template/:idOperator/:templateDate/:idMaterial" element={<ProtectedRoute locking={true} element={<Material />} />} />
                     <Route path="/template/:idOperator/addTemplate" element={<ProtectedRoute locking={true} element={<AddTemplate />} />} />

                     {/* Rules */}
                     <Route path="/rule/:idOperator/addRule" element={<ProtectedRoute locking={true} element={<AddRule />} />} />
                     <Route path="/rule/subRules/:idOperator/:component/:idRule" element={<ProtectedRoute locking={true} element={<SubRule />} />} />
                     <Route path="/rule/:idOperator/:component" element={<ProtectedRoute locking={true}  element={<Rule />} />} />
                     <Route path="/rule/:idOperator/:component/:idRule" element={<ProtectedRoute locking={true}  element={<Rule />} />} />
                     
                     {/* Projects */}
                     <Route path="/gestProjects" element={<ProtectedRoute locking={true} element={<GestProjects />} />} />
                     <Route path="/project/:idOperator/:idProject" element={<ProtectedRoute locking={true} element={<Project />} />} />

                     {/* Users */}
                     <Route path="/users" element={<ProtectedRoute locking={false} element={<Users />} />} /> 

                     {/* Logs */}
                     <Route path='/logs' element={<ProtectedRoute locking={false} element={<Logs/>}/>}/>

                     {/* Everything else */}
                     <Route  path='/*' element={<NotFound/>} />
                  </Routes>
               </div>
               <ToastContainer autoClose={5000}/>
            </Router>
            </AuthProvider>
         ) : (
            <Loading/>
         )
      }
      </>
   )
}