// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingPage_landing-page__fGu\\+p {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.LandingPage_landing-buttons__q9AVx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/LandingPage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,SAAA;AACF","sourcesContent":[".landing-page {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.landing-buttons {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-page": `LandingPage_landing-page__fGu+p`,
	"landing-buttons": `LandingPage_landing-buttons__q9AVx`
};
export default ___CSS_LOADER_EXPORT___;
