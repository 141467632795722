import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
   downloadTabLig,
   executeTabLig,
   getTabLigResults,
} from '../../../../controllers/tabLig'
import { downloadProjectFile } from '../../../../controllers/project'
import { useParams } from 'react-router-dom'
import { useTabLigContext } from '../../../context/TabLigContext'
import ButtonTabs from '../../../components/ButtonTabs'

function TabLig() {
   const { idOperator, idProject } = useParams()
   const { tablig, updateTabLig } = useTabLigContext()
   const [results, setResults] = useState([])
   const [displayedImage, setDisplayedImage] = useState(null)
   const [progress, setProgress] = useState(null)

   useEffect(() => {
      if (!tablig) {
         loadResults()
      }
   }, [tablig])

   const loadResults = async () => {
      const tabligResults = await getTabLigResults({
         idOperator,
         idProject
      })

      if (tabligResults && tabligResults.length > 0) {
         setResults(tabligResults)
      }
   }

   const handleExecuteTabLig = async (event) => {
      updateTabLig(true)

      const results = await executeTabLig({
         idOperator,
         idProject,
      })

      updateTabLig(false)
   }

   const downloadFile = async (file) => {
      await downloadTabLig(
         {
            idOperator,
            idProject,
            file,
         },
         (loaded) => {
            console.log(loaded.loaded, loaded.total)
            const progress = Math.round((loaded.loaded / loaded.total) * 100)
            setProgress(progress)
         }
      )

      setProgress(undefined)
   }

   return (
      <>
         <div className="grid-two">
            <div className="grid-column">
               <div className="column-title">
                  <h2>Alocação Juntas</h2>
               </div>
               <hr></hr>
               <ul className="button-list-ver">
                  <li className="button-item">
                     <button
                        className="button"
                        onClick={(_) => handleExecuteTabLig()}
                        disabled={tablig}
                     >
                        Gerar Alocação Juntas
                     </button>
                  </li>
               </ul>
            </div>

            <div className="grid-column">
               <h3>Resultados</h3>
               <hr></hr>
               <ul className="card-list">
                  {results.length > 0 ? (results.map((s) => (
                     <li className="card" key={s}>
                        <div className="card-content-hor">
                           <ul>
                              <li>
                                 <p>
                                    Arquivo: <strong>{s}</strong>
                                 </p>
                              </li>
                           </ul>

                           <ul className="button-list-hor">
                              <li className="button-item">
                                 <button
                                    className="button"
                                    onClick={(_) => downloadFile(s)}
                                    disabled={progress}
                                 >
                                    Transferir
                                 </button>
                              </li>
                           </ul>
                        </div>
                     </li>
                  ))) : (
                     <h3>Sem Resultados...</h3>
                  )}
               </ul>
            </div>
         </div>
      </>
   )
}

export default TabLig
