// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* Primary */
  --bright-blue: #006fff;
  --bright-green: #17daa4;
  --dark-blue: #010060;
  --light-gray-01: #dddddd;
  --light-blue: #e2eaff;
  --almost-white: #f8f8f8;
  --almost-black: #1f1f1f;
  /* Secundary */
  --medium-blue: #87a9ff;
  --medium-green: #b0ebdb;
  --medium-dark-blue: #7d7dab;
  --medium-gray-01: #c2c2c2;
  --light-gray-02: #ededed;
  --light-blue: #cedcff;
  --dark-gray: #6c6c6c;
  /* Tertiary */
  --warm-yellow: #ebc25a;
  --warm-orange: #eb745a;
  --light-yellow: #ffedbf;
  --dark-yellow: #cfa948;
  --bright-purple: #801de2;
  /* Alert */
  --rejected: #de4321;
  --approved: #1dc88a;
  --stand-by: #ffcc4a;
  /* Notifications */
  --bg-light-yellow: #ffedbf;
  --dark-yellow: #856404;
  --bg-light-red: #ffbdae;
  --dark-red: #842029;
  --bg-success: #8ef8d2;
  --dark-green: #0f5132;
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,oBAAA;EACA,wBAAA;EACA,qBAAA;EACA,uBAAA;EACA,uBAAA;EAEA,cAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,yBAAA;EACA,wBAAA;EACA,qBAAA;EACA,oBAAA;EAEA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,sBAAA;EACA,wBAAA;EAEA,UAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,0BAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;AAHF","sourcesContent":[":root {\n  /* Primary */\n  --bright-blue: #006fff;\n  --bright-green: #17daa4;\n  --dark-blue: #010060;\n  --light-gray-01: #dddddd;\n  --light-blue: #e2eaff;\n  --almost-white: #f8f8f8;\n  --almost-black: #1f1f1f;\n\n  /* Secundary */\n  --medium-blue: #87a9ff;\n  --medium-green: #b0ebdb;\n  --medium-dark-blue: #7d7dab;\n  --medium-gray-01: #c2c2c2;\n  --light-gray-02: #ededed;\n  --light-blue: #cedcff;\n  --dark-gray: #6c6c6c;\n\n  /* Tertiary */\n  --warm-yellow: #ebc25a;\n  --warm-orange: #eb745a;\n  --light-yellow: #ffedbf;\n  --dark-yellow: #cfa948;\n  --bright-purple: #801de2;\n\n  /* Alert */\n  --rejected: #de4321;\n  --approved: #1dc88a;\n  --stand-by: #ffcc4a;\n\n  /* Notifications */\n  --bg-light-yellow: #ffedbf;\n  --dark-yellow: #856404;\n  --bg-light-red: #ffbdae;\n  --dark-red: #842029;\n  --bg-success: #8ef8d2;\n  --dark-green: #0f5132;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
