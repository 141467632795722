import axios from 'axios'
import Cookies from 'js-cookie'

import { statusCheck } from './middlewares/responseChecker'
import { env } from '../config/config'

const API = env('SERVER_API')

export const checkPreTabLig = async (args) => {
   try {
      const { idOperator, idProject } = args

      const res = await axios.post(
         `${API}/generatorTabLig/preTabLig/check`,
         {
            idOperator,
            idProject,
         },
         JSON.parse(Cookies.get('authHeader'))
      )

      if (statusCheck(res)) {
         return res.data
      }
   } catch (error) {
      console.log('ERRO: executePreTabLig')
   }
}

export const executePreTabLig = async (args) => {
   try {
      const { idOperator, idProject } = args

      const res = await axios.post(
         `${API}/generatorTabLig/executePreTabLig`,
         {
            idOperator,
            idProject,
         },
         {
            ...JSON.parse(Cookies.get('authHeader')),
         }
      )

      if (statusCheck(res)) {
         return true
      }
   } catch (error) {
      console.log('ERRO: executePreTabLig')
   }
}

export const getTabLigResults = async (args) => {
   console.log('getTabLigResults')
   try {
      const { idOperator, idProject } = args

      const res = await axios.get(
         `${API}/generatorTabLig/tabligResults?idOperator=${idOperator}&idProject=${idProject}`,
         JSON.parse(Cookies.get('authHeader'))
      )

      if (statusCheck(res)) {
         return res.data
      }
   } catch (error) {
      console.log('ERRO: getTabLigResults')
   }
}

export const executeTabLig = async (args) => {
   try {
      const { idOperator, idProject } = args
     
      const res = await axios.get(
         `${API}/generatorTabLig/executeTabLig`, {
         params: {
            idOperator,
            idProject
         },
         ...JSON.parse(Cookies.get('authHeader')),
      })
     
      if (statusCheck(res)) {
         return true
      } else {
         return false
      }
   } catch (error) {
      console.log('ERRO: executeTabLig')
   }
}

export const downloadTabLig = async (args, downloadProgress) => {
   try {
      const { idOperator, idProject, file } = args

      const controller = new AbortController()
      const signal = controller.signal

      const res = await axios({
         url: `${API}/generatorTabLig/tabligResults/download`,
         method: 'GET',
         params: {
            idOperator,
            idProject,
            file,
         },
         responseType: 'blob',
         ...JSON.parse(Cookies.get('authHeader')),
         onDownloadProgress: downloadProgress,
         signal,
      })

      if (statusCheck(res)) {
         const url = window.URL.createObjectURL(new Blob([res.data]))
         const link = document.createElement('a')
         link.href = url
         link.setAttribute('download', file)
         document.body.appendChild(link)
         link.click()
         window.URL.revokeObjectURL(url)

         return true
      } else {
         controller.abort()
         return false
      }
   } catch (error) {
      console.log('ERRO: getProjects')
   }
}
