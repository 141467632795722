import React, { createContext, useContext, useState } from 'react'

const TabLigContext = createContext()

export const useTabLigContext = () => useContext(TabLigContext)

export const TabLigProvider = ({ children }) => {
   const [tablig, setTabLig] = useState(false)

   const updateTabLig = (status) => {
      setTabLig(status)
   }

   return (
      <TabLigContext.Provider value={{ tablig, updateTabLig }}>
         {children}
      </TabLigContext.Provider>
   )
}
