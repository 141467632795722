import React from 'react'
import styles from './LandingPage.module.scss'
import { Button, Header, Label, useAuth } from '@nextelco/common-ui'

import HomeImg from '../assets/svgs/Home_NEXTelco.svg'
import ProefImg from '../assets/svgs/proef_1.svg'
import HomeTitleImg from '../assets/svgs/NEXTelco_logo.svg'

import {
  faPager,
  faUser,
  faWifi,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'

type Props = {}

const LandingPage = (props: Props) => {
  const { logout, user } = useAuth()

  const externalRoutes = {
    nextelco: 'https://nextelco.proef.com/next',
    fieldForceSupport: 'https://nextelco.proef.com/field',
    bop: 'https://nextelco.proef.com/bop',
    portalObra: 'https://nextelco.proef.com/portal',
  }

  const navigateExternal = (url: string) => {
    window.location.href = url
  }

  return (
    <div className={styles['landing-page']}>
      <Header
        returnToHome={() => {}}
        returnLogout={logout}
        user={user!}
        homeImg={HomeImg}
        homeTextImg={HomeTitleImg}
        logoImg={ProefImg}
      />
      <div className={styles['landing-buttons']}>
        <Button
          onClick={() => navigateExternal(externalRoutes.nextelco)}
          mode="cui-options_home_screen"
          size="cui-ext_lg"
          variant="cui-actions"
          iconPrev={faWifi}
        >
          Nextelco
        </Button>
        <Button
          onClick={() => navigateExternal(externalRoutes.fieldForceSupport)}
          mode="cui-options_home_screen"
          size="cui-ext_lg"
          variant="cui-actions"
          iconPrev={faWrench}
        >
          Field Force Support
        </Button>
        <Button
          onClick={() => navigateExternal(externalRoutes.bop)}
          mode="cui-options_home_screen"
          size="cui-ext_lg"
          variant="cui-actions"
          iconPrev={faPager}
        >
          BOP
        </Button>
        <Button
          onClick={() => navigateExternal(externalRoutes.portalObra)}
          mode="cui-options_home_screen"
          size="cui-ext_lg"
          variant="cui-actions"
          iconPrev={faUser}
        >
          Portal Obra
        </Button>
      </div>
    </div>
  )
}

export default LandingPage
