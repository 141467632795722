// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[8].use[3]!../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[4]!./utils/reset.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[8].use[3]!../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[4]!./utils/variables.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[8].use[3]!../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[4]!./utils/scrollbar.scss";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./font/static/Urbanist-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Urbanist";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Urbanist", sans-serif;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.module.scss"],"names":[],"mappings":"AAIA;EACE,uBAAA;EACA,4CAAA;AAAF;AAGA;;EAEE,YAAA;EACA,SAAA;AADF;;AAIA;EACE,mCAAA;EACA,uBAAA;AADF","sourcesContent":["@import url('./utils/reset.scss');\n@import url('./utils/variables.scss');\n@import url('./utils/scrollbar.scss');\n\n@font-face {\n  font-family: 'Urbanist';\n  src: url('./font/static/Urbanist-Regular.ttf');\n}\n\nhtml,\nbody {\n  height: 100%;\n  margin: 0;\n}\n\nbody {\n  font-family: 'Urbanist', sans-serif;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
