// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-blue);
  border-radius: 15px;
}

.scrollbar_scroll-bar__vyOiM::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.scrollbar_scroll-bar__vyOiM::-webkit-scrollbar-track {
  background: var(--light-gray-01);
  border-radius: 15px;
}

.scrollbar_scroll-bar__vyOiM::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: var(--dark-blue);
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/scrollbar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,4BAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;AACF;;AAEA;EACE,gCAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,4BAAA;AACF","sourcesContent":["::-webkit-scrollbar {\n  width: 10px;\n  height: 10px;\n}\n\n::-webkit-scrollbar-track {\n  background: #f1f1f1;\n  border-radius: 15px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 15px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: var(--dark-blue);\n  border-radius: 15px;\n}\n\n.scroll-bar::-webkit-scrollbar {\n  width: 10px;\n  height: 5px;\n}\n\n.scroll-bar::-webkit-scrollbar-track {\n  background: var(--light-gray-01);\n  border-radius: 15px;\n}\n\n.scroll-bar::-webkit-scrollbar-thumb {\n  border-radius: 15px;\n  background: var(--dark-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll-bar": `scrollbar_scroll-bar__vyOiM`
};
export default ___CSS_LOADER_EXPORT___;
