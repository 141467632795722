// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage_landing-page__Syuuj {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACF","sourcesContent":[".landing-page {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-page": `NotFoundPage_landing-page__Syuuj`
};
export default ___CSS_LOADER_EXPORT___;
